import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/wedding_planner1.svg"
import thumbnailStep from "../../static/images/step.svg"
import thumbnailEvent from "../../static/images/story-2.png"
import thumbnailBoard from "../../static/images/story-3.png"
import map from "../../static/images/map.png"

const Services = () => (
    <Layout>
        <SEO title="Un accompagnement personnalisé"/>
            <div className={"container"}>
                <div className={"features"}>
                    <div className={"feature__item"}>
                        <div className={"row"}>
                            <div className={"col-12"} >
                                <div className={"feature__content"}>
                                    <h2>A vos cotés de la mise en relation au shooting photo</h2>
                                </div>
                            </div>
                            <div className={"col-6 first"}>
                                <div className={"thumbnail"}>
                                    <img alt={"Event"} src={thumbnailEvent}/>
                                </div>
                            </div>
                            <div className={"col-6 first"}>
                                <div className={"thumbnail"}>
                                    <img alt={"Event"} src={thumbnailStep}/>
                                    {/* <div className={"link"}>
                                        <h3>Mise en relation avec <br/>le photographe</h3>
                                    </div> */}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"feature__item1"}>
                        
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"row"}>
                                <div className={"feature__content"}>
                                    <h2>Les avantages de VIVR pour des photos réussies</h2>
                                </div>

                                </div>
                            </div>
                            <div className={"col-6 first"}>
                                <div className={"thumbnail"}>
                                    <div className={"row"}>
                                        <img alt={"Carte de la France avec nos photographes"} src={map}/>
                                        <div className={"feature__content"}>
                                            <h3>Un réseau de photographes professionnels à portée de clic </h3>
                                        </div>
                                    </div>    
                                </div>
                                
                            </div>

                            <div className={"col-6"}>
                                <div className={"thumbnail"}>
                                    <div className={"row"}>
                                        <img alt={"Board"} src={thumbnailBoard}/>
                                        <div className={"feature__content"}>
                                            <h3>Une sélection rigoureuse de nos partenaires pour une prestation réussie </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

    </Layout>
)

export default Services
